import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Headline from '../BodyComponents/Headline'
import BodyContainer from '../BodyContainer'
import BodyPanelContainer from '../BodyPanelContainer'
import Layout from '../layout'

const FourOhFourContainer = styled(BodyPanelContainer)`
  margin-top: 100px;
  padding: 20px;
`

const FourOhFour = styled(Headline)`
`

const FourOhFourDetail = styled(Headline)`
`

const FourOhFourPage = (props) => {

  const {t} = useTranslation('translation');

  const bodyProps = t('404', { returnObjects: true });

  return (
    <Layout>
      <Helmet>
        <title>{t('siteTitle')} - Not Found</title>
      </Helmet>
      <BodyContainer>
        <FourOhFourContainer>
            <FourOhFour h={2} hideTopArrow={true}>{bodyProps.headline}</FourOhFour>
            <FourOhFourDetail h={3} hideTopArrow={true}>{bodyProps.detail}</FourOhFourDetail>
        </FourOhFourContainer>
      </BodyContainer>
    </Layout>
  )
}

export default FourOhFourPage